import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { LANGUAGE_TYPES } from '@bvl-admin/settings/constants/general.constant';

@Component({
  selector: 'admin-item-language',
  templateUrl: './item-language.component.html',
  styleUrls: ['./item-language.component.scss']
})
export class ItemLanguageComponent implements OnInit {

  @HostBinding('attr.class') attrClass = 'g-info-w-icon';

  languagesList: Array<{}>;

  protected _languages: Array<string>;
  @Input()
  get languages(): Array<string> {
    return this._languages;
  }
  set languages(value: Array<string>) {
    this._languages = value;
    this.languagesList = this._getLanguagesList(value);
  }

  constructor() {
    this.languagesList = [];
  }

  ngOnInit(): void { }

  private _getLanguagesList(languages): Array<{}> {
    return LANGUAGE_TYPES.filter((fv, fk) => {
      return languages.find((sv, sk) => fv.code === sv);
    });
  }

}
