import { Component, Input, OnInit } from '@angular/core';
import { STATES } from '@bvl-admin/settings/constants/general.constant';

@Component({
  selector: 'admin-label-state',
  templateUrl: './label-state.component.html',
  styleUrls: ['./label-state.component.scss']
})
export class LabelStateComponent implements OnInit {

  protected _state: number;
  @Input()
  get state(): number {
    return this._state;
  }
  set state(value: number) {
    const state = STATES.find(item => item.code === value);
    if (state) { this.labelState = state; }
  }

  labelState: { code: number, description: string, labelClass: string };

  constructor() { }

  ngOnInit(): void { }

}
