import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItemLanguageComponent } from './item-language.component';

@NgModule({
  declarations: [ItemLanguageComponent],
  imports: [
    CommonModule
  ],
  exports: [ItemLanguageComponent]
})
export class ItemLanguageModule { }
