import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LabelStateComponent } from './label-state.component';

@NgModule({
  declarations: [LabelStateComponent],
  imports: [
    CommonModule
  ],
  exports: [LabelStateComponent]
})
export class LabelStateModule { }
